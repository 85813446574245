import { useState } from 'react'
import { Company } from '../data/types'
import take from 'lodash/take'
import { useEcLocalStorageState } from '@toasttab/ec-storage'
import { useSwitchClientMutation } from '../data'

type Args = {
  redirectUrl?: string
}

type Variables = {
  company: Company
  redirectUrl?: string
}

const useSwitchCompany = (args?: Args) => {
  const mutation = useSwitchClientMutation({ companyCode: 'toast' })

  const [, setRecent] = useEcLocalStorageState(
    'company-switcher-recent-companies'
  )

  const [isNavigating, setIsNavigating] = useState<boolean>(false)

  const onSwitch = async (variables: Variables) => {
    const { company } = variables

    const { id, companyCode, dbHash } = company

    const mutationVars = { id, companyCode, dbHash }

    mutation.mutate(mutationVars, {
      onSuccess: (resp) => {
        if (resp) {
          setRecent((recent) => take([company, ...recent], 10))

          const url =
            variables.redirectUrl || //
            args?.redirectUrl ||
            resp.redirectURL

          setIsNavigating(true)

          window.location.href = url
        } else {
          throw new Error(`Unable to switch to '${companyCode}'`)
        }
      }
    })
  }

  return {
    onSwitch,
    isSwitching: mutation.isLoading || isNavigating
  }
}
export default useSwitchCompany

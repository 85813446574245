import * as React from 'react'
import { Block } from '@toasttab/buffet-pui-dashboard-layout'
import { DashboardCard } from '@toasttab/buffet-pui-card'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

type Props = {
  cols?: '1' | '2' | '3'
  rows?: '1' | '2' | '3'
  title: string
  isLoading?: boolean
  children: React.ReactNode
}

const Card = (props: Props) => {
  const { cols = '1', rows = '1', title, isLoading, children } = props
  return (
    <Block cols={cols} rows={rows}>
      <DashboardCard className='h-full' cardTitle={title}>
        {isLoading ? (
          <div>
            <MerryGoRound />
          </div>
        ) : (
          children
        )}
      </DashboardCard>
    </Block>
  )
}

export default Card

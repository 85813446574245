import * as React from 'react'
import { useState, useMemo } from 'react'
import { Dashboard } from '@toasttab/buffet-pui-dashboard-layout'
import { LinkButton, Button } from '@toasttab/buffet-pui-buttons'
import { useGetClientsQuery } from './data'
import CompaniesList from './CompaniesList/CompaniesList'
import keys from 'lodash/keys'
import countBy from 'lodash/countBy'
import filter from 'lodash/filter'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import map from 'lodash/map'
import sample from 'lodash/sample'
import sortBy from 'lodash/sortBy'
import ShardsList from './components/ShardsList'
import { getCountSuffix } from './helpers'
import { Card, StarredCompanies } from './components'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useToastCompany, useSwitchCompany, useRecentCompanies } from './hooks'

const StaffDashboard = () => {
  useToastCompany()

  const query = useGetClientsQuery({ companyCode: 'toast' })

  const companies = query.data

  const { onSwitch, isSwitching } = useSwitchCompany()

  const recentCompanies = useRecentCompanies()

  const shardCounts = useMemo(() => {
    return countBy(companies, 'shard')
  }, [companies])

  const allShards = useMemo(() => keys(shardCounts), [shardCounts])

  const [shards, setShards] = useState<string[]>([])

  const visibleCompanies = useMemo(() => {
    if (isEmpty(shards)) return companies
    return filter(companies, (company) => {
      return some(shards, (shard) => shard === company.shard)
    })
  }, [companies, shards])

  const shardOptions = useMemo(() => {
    const list = map(allShards, (shard) => {
      const count = shardCounts[shard]
      return { label: shard, value: shard, count }
    })
    return sortBy(list, (item) => item.count || 0).reverse()
  }, [allShards, shardCounts])

  const aPhoenixCompany = useMemo(() => {
    const pheonixCompanies = filter(companies, (company) => {
      return company.shard === 'phoenix'
    })
    return sample(pheonixCompanies)
  }, [companies])

  return (
    <Dashboard>
      <Card title='Toast Staff Dashboard' cols='3'>
        <p>
          The <b>toast</b> company is a meta-company for toast employees. It
          doesn't have any real employees and most features won't work, but it's
          a jumping off point for switching to other companies.
        </p>
      </Card>
      <Card
        title={`Companies${getCountSuffix(size(companies))}`}
        cols='2'
        rows='2'
        isLoading={query.isLoading}
      >
        <ShardsList
          total={size(companies)}
          shards={shardOptions}
          value={shards}
          onChange={setShards}
        />

        <CompaniesList
          companies={visibleCompanies}
          onSelect={(company) => onSwitch({ company })}
          limit={12}
          placeholder={`Search companies${getCountSuffix(
            size(visibleCompanies)
          )}...`}
        />
      </Card>

      <Card title='Starred'>
        <StarredCompanies onSwitch={(company) => onSwitch({ company })} />
      </Card>
      <Card title='Recent'>
        <CompaniesList
          companies={recentCompanies}
          onSelect={(company) => onSwitch({ company })}
          limit={5}
          placeholder='Search Recent'
        />
      </Card>
      <Card title='Implementation' cols='2'>
        {aPhoenixCompany && (
          <div>
            <Button
              className='block'
              variant='link'
              onClick={() => {
                const { companyCode } = aPhoenixCompany
                onSwitch({
                  company: aPhoenixCompany,
                  redirectUrl: `/${companyCode}/implementation/create-company`
                })
              }}
            >
              {`Create new company (via ${aPhoenixCompany.companyCode} on ${aPhoenixCompany.shard})`}
            </Button>
          </div>
        )}
        <div>
          <LinkButton href='/staff-tools'>Staff Tools</LinkButton>
        </div>
      </Card>
      {isSwitching && (
        <div className='bg-darken-12 absolute inset-0'>
          <div className='pin-center'>
            <MerryGoRound />
          </div>
        </div>
      )}
    </Dashboard>
  )
}

export default StaffDashboard

import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import StaffAppLayout from './StaffAppLayout'
import StaffDashboard from './StaffDashboard'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import StaffDashboardWrapper from './StaffDashboardWrapper'

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: '/staff-dashboard',
    element: (
      <QueryClientProvider client={queryClient}>
        <StaffAppLayout title='Toast Staff Dashboard'>
          <StaffDashboardWrapper>
            <StaffDashboard />
          </StaffDashboardWrapper>
        </StaffAppLayout>
      </QueryClientProvider>
    )
  },
  {
    path: '*',
    element: null
  }
])

const StaffApp = () => {
  return <RouterProvider router={router} />
}

export default StaffApp

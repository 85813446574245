import * as React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { domElementGetter } from '@toasttab/ec-layout'
import StaffApp from './app/StaffApp'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-pui-2',
  rootComponent: StaffApp,
  domElementGetter: domElementGetter.application,
  portalContainers: ['banquetPortalsContainer']
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'ec-staff-dashboard-spa'

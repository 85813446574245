import { useEffect } from 'react'
import { useGetClientsQuery } from '../data'
import { Company } from '../data/types'
import find from 'lodash/find'
import { useEcLocalStorageState } from '@toasttab/ec-storage'

const useToastCompany = (): Company | null => {
  const query = useGetClientsQuery({ companyCode: 'toast' })

  const companies = query.data

  const [cachedToast, setCachedToast] = useEcLocalStorageState(
    'company-switcher-toast-company'
  )

  const toastCompany =
    cachedToast ||
    find(companies, (company) => company.companyCode === 'toast') ||
    null

  useEffect(() => {
    if (!cachedToast && toastCompany) {
      setCachedToast(toastCompany)
    }
  }, [setCachedToast, cachedToast, toastCompany])

  return toastCompany || null
}

export default useToastCompany

import * as React from 'react'
import { Company } from '../data/types'
import { RestaurantIcon, StarIcon, MenuIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { useEcLocalStorageState } from '@toasttab/ec-storage'
import some from 'lodash/some'
import reject from 'lodash/reject'
import { MenuDropdown, ListItem } from '@toasttab/buffet-pui-dropdowns'
import cx from 'classnames'

type Props = {
  company: Company
  onSwitch: () => void
}

const CompanyItem = (props: Props) => {
  const { company, onSwitch } = props

  const { id, name, shard } = company

  const [starred, setStarred] = useEcLocalStorageState(
    'company-switcher-starred-companies'
  )

  const isStarred = some(starred, (star) => star.id === id)

  const icon = (
    <span
      className={cx(
        'inline-block',
        isStarred ? 'text-brand-50' : 'text-disabled'
      )}
    >
      <StarIcon size='xs' />
    </span>
  )

  return (
    <div className='flex flex-row justify-between items-center hover:bg-darken-4 py-1 px-2 cursor-pointer'>
      <div
        className='flex flex-row items-center grow py-1 px-2 cursor-pointer'
        onClick={onSwitch}
      >
        <span className='inline-block relative text-secondary'>
          <RestaurantIcon />
        </span>
        <span className='inline-block pl-4'>
          <div className='type-default'>{name}</div>
          <div className='text-disabled'>{shard}</div>
        </span>
      </div>

      <div className='flex flex-row items-center'>
        <MenuDropdown
          renderToggle={(props) => <IconButton icon={icon} {...props} />}
        >
          {isStarred ? (
            <ListItem
              onClick={() => setStarred((starred) => reject(starred, { id }))}
              icon={<StarIcon />}
              label='Unstar'
            />
          ) : (
            <ListItem
              onClick={() => setStarred((starred) => [...starred, company])}
              icon={<StarIcon />}
              label='Star'
            />
          )}
        </MenuDropdown>
      </div>
    </div>
  )
}

export default CompanyItem

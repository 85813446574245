import * as React from 'react'
import { useSwitchToToastIfNot } from './hooks'

type Props = {
  children: React.ReactNode
}

const StaffDashboardWrapper = (props: Props) => {
  const { children } = props

  const { isToast } = useSwitchToToastIfNot()

  if (isToast) {
    return <>{children}</>
  }
  return <div>Switching to toast company...</div>
}

export default StaffDashboardWrapper

import { SearchInput } from '@toasttab/buffet-pui-text-input'
import * as React from 'react'

type Props = {
  value: string
  onChange: (str: string) => void
  placeholder?: string
}

const SearchBox = (props: Props) => {
  const { value, onChange, placeholder } = props

  return (
    <SearchInput
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type='text'
      containerClassName='w-full'
      autoComplete='off'
      placeholder={placeholder || 'Search Companies'}
      testId='search-bar-input'
    />
  )
}

export default SearchBox

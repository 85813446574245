import { apiCall } from '../helpers'

import { useMutation } from '@tanstack/react-query'

type Args = {
  companyCode: string
}

type Variables = {
  id: number
  companyCode: string
  dbHash: string
}

const useSwitchClientMutation = (args: Args) => {
  const { companyCode: useCompanyCode } = args

  return useMutation({
    mutationFn: async (variables: Variables) => {
      const { id, companyCode, dbHash } = variables

      const resp = await apiCall({
        method: 'POST',
        url: `/mvc/${useCompanyCode}/Dashboard/Root/switchClient`,
        body: {
          newCustomerId: id,
          newCustomerCode: companyCode,
          newDB: dbHash
        }
      })

      return resp
    }
  })
}

export default useSwitchClientMutation

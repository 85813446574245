import * as React from 'react'
import CompaniesList from '../CompaniesList/CompaniesList'
import { Company } from '../data/types'
import { useEcLocalStorageState } from '@toasttab/ec-storage'

type Props = {
  onSwitch: (company: Company) => void
}

const StarredCompanies = (props: Props) => {
  const { onSwitch } = props

  const [starred] = useEcLocalStorageState('company-switcher-starred-companies')

  const companies: Company[] = starred.map((item) => {
    // @ts-ignore
    const { name, id, companyCode, dbHash, shard } = item
    return {
      name,
      id,
      companyCode,
      shard: shard || '???',
      dbHash
    }
  })

  return (
    <CompaniesList
      companies={companies}
      onSelect={onSwitch}
      limit={5}
      placeholder='Search Starred'
    />
  )
}

export default StarredCompanies

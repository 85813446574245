import * as React from 'react'

type Props = {
  title: React.ReactNode
  actions?: React.ReactNode
  children: React.ReactNode
}

const StaffAppLayout = (props: Props) => {
  const { title, actions, children } = props

  return (
    <div className='pt-10 pb-6'>
      <div className='flex flex-row items-center justify-between'>
        <div className='pb-4 type-headline-2'>{title}</div>

        {actions && <div>{actions}</div>}
      </div>

      <div className='pt-6'>{children}</div>
    </div>
  )
}

export default StaffAppLayout

import map from 'lodash/map'
import { apiCall } from '../helpers'
import { useQuery } from '@tanstack/react-query'
import type { Client, Company } from './types'

type Resp = {
  obj: Client[]
}

type Args = {
  companyCode: string
}

const parseClient = (client: Client): Company => {
  const { id, name, customerId, dbHash } = client

  const [shortName = '', shardStr = ''] = name.split('(')
  const displayName = shortName.trim() || '[No Name]'
  const [shard = '???'] = shardStr.split(')')

  return {
    id,
    name: displayName,
    companyCode: customerId || '[No Company Code]',
    shard,
    dbHash
  }
}

const useGetClientsQuery = (args: Args) => {
  const { companyCode } = args

  return useQuery({
    queryFn: async () => {
      const resp = await apiCall<Resp>({
        method: 'GET',
        url: `/mvc/${companyCode}/Team/Dashboard/GetClients`
      })

      return map(resp.obj, (client) => parseClient(client))
    },
    queryKey: ['clients']
  })
}

export default useGetClientsQuery

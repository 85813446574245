import { useEffect } from 'react'
import useToastCompany from './useToastCompany'
import useSwitchCompany from './useSwitchCompany'

// @ts-ignore
const companyCode = window.Toast.user.client

const isToast = companyCode === 'toast'

const useSwitchToToastIfNot = () => {
  const toastCompany = useToastCompany()

  const { onSwitch } = useSwitchCompany()

  const hasCompany = !!toastCompany

  useEffect(() => {
    if (!isToast && hasCompany) {
      onSwitch({
        company: toastCompany,
        redirectUrl: '/staff-dashboard'
      })
    }
  }, [hasCompany]) // eslint-disable-line react-hooks/exhaustive-deps

  return { isToast, toastCompany }
}

export default useSwitchToToastIfNot

import * as React from 'react'
import without from 'lodash/without'
import { Badge } from '@toasttab/buffet-pui-badge'
import { getCountSuffix } from '../helpers'

type Shard = {
  label: string
  value: string
  count: number
}

type ShardBadgeProps = {
  label: string
  count?: number
  isActive: boolean
  onSelect: () => void
  onAdd?: () => void
}

const ShardBadge = (props: ShardBadgeProps) => {
  const { label, count, isActive, onSelect, onAdd } = props

  const color = isActive ? 'neutral' : 'gray'

  const suffix = count && getCountSuffix(count)

  return (
    <span
      onClick={(event) => {
        const isSpecial = event.metaKey || event.ctrlKey || event.altKey
        if (isSpecial && onAdd) {
          onAdd()
        } else {
          onSelect()
        }
      }}
      className='inline-block p-1 cursor-pointer'
    >
      <Badge color={color}>
        <span className='inline-block font-semibold text-secondary'>
          {label}
        </span>
        {suffix && (
          <span className='inline-block font-normal type-caption text-disabled pl-1'>
            {suffix}
          </span>
        )}
      </Badge>
    </span>
  )
}

type Props = {
  total: number | undefined
  shards: Shard[]
  value: string[]
  onChange: (shards: string[]) => void
}

const ShardsList = (props: Props) => {
  const { total, shards, value, onChange } = props

  const isAllSelected = value.length === 0

  return (
    <div className='p-1'>
      <ShardBadge
        isActive={isAllSelected}
        onSelect={() => onChange([])}
        label='All'
        count={total}
      />
      {shards.map((shard) => {
        const { value: val, label, count } = shard

        const isActive = value.includes(val)

        const handleSelect = () => onChange([val])

        const handleAdd = () => {
          onChange(isActive ? without(value, val) : [...value, val])
        }
        return (
          <ShardBadge
            key={val}
            isActive={isActive}
            onSelect={handleSelect}
            count={count}
            onAdd={handleAdd}
            label={label}
          />
        )
      })}
    </div>
  )
}

export default ShardsList

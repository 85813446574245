import * as React from 'react'
import { useState, useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import filter from 'lodash/filter'
import take from 'lodash/take'
import compact from 'lodash/compact'
import uniqBy from 'lodash/uniqBy'
import SearchBox from './SearchBox'
import { useDebounceValue } from '../hooks'
import { Company } from '../data/types'
import CompanyItem from './CompanyItem'

const matches = (rawVal: string = '', rawQuery: string = '') => {
  const query = rawQuery.trim().toLowerCase()
  if (!query) return true
  const val = rawVal.trim().toLowerCase()
  if (!val) return false
  const tokens = compact(query.split(' '))
  for (const token of tokens) {
    if (!val.includes(token)) return false
  }
  return true
}

type Args = {
  companyNames: string[]
  companiesByCompanyCode: Record<string, Company>
  companiesByName: Record<string, Company>
  search: string
  limit?: number
}

const filterCompanies = (args: Args) => {
  const {
    companyNames,
    companiesByCompanyCode,
    companiesByName,
    search,
    limit = 10
  } = args

  const names = filter(companyNames, (name) => {
    if (!search) return true
    return matches(name, search)
  })

  const truncatedNames = take(names, limit)

  const matchClinets = map(truncatedNames, (name) => {
    return companiesByName[name]
  })

  const exactMatch = companiesByCompanyCode[search]

  return uniqBy(compact([exactMatch, ...matchClinets]), 'companyCode')
}

type Props = {
  companies: Company[] | undefined
  onSelect: (company: Company) => void
  limit?: number
  placeholder?: string
}

const CompaniesList = (props: Props) => {
  const { companies = [], onSelect, limit = 10, placeholder } = props

  const count = companies.length

  const hasSearch = count > limit

  const companiesByCompanyCode = useMemo(() => {
    return keyBy(companies, 'companyCode')
  }, [companies])

  const companiesByName = useMemo(() => {
    return keyBy(companies, 'name')
  }, [companies])

  const companyNames = useMemo(() => {
    return map(companies, (company) => company.name)
  }, [companies])

  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useDebounceValue(search)

  const results = useMemo(() => {
    return filterCompanies({
      companyNames,
      companiesByName,
      companiesByCompanyCode,
      search: debouncedSearch,
      limit
    })
  }, [
    companyNames,
    companiesByName,
    companiesByCompanyCode,
    debouncedSearch,
    limit
  ])

  return (
    <div className='p-2 w-full'>
      {hasSearch && (
        <SearchBox
          value={search}
          onChange={setSearch}
          placeholder={placeholder}
        />
      )}
      <div className='pt-2'>
        {map(results, (company) => {
          const { companyCode } = company

          return (
            <CompanyItem
              key={companyCode}
              company={company}
              onSwitch={() => onSelect(company)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default CompaniesList
